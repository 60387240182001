import "./App.css";
import React, { Suspense, lazy, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Routes, Route, useLocation } from "react-router-dom";
import { getStudent } from "./redux/student/auth/studentAuthSlice";
import Loader from "./Loader";
import StudentLayout from "./layout/Student";
import StudentCompaniesRoute from "./pages/student/companies";
import StudentProfileRoutes from "./pages/student/profile";
import StudentTestRoute from "./pages/student/test";
import StudentSettingsRoute from "./pages/student/settings";
import StudentJobsRoute from "./pages/student/jobs";
import StudentDashRoute from "./pages/student/dash";
import StudentInboxRoute from "./pages/student/inbox";
import StudentResultsRoute from "./pages/student/results";
import NotAuth from "./components/PopUps/NotAuth";
import TestHome from "./pages/student/test/TestHome";
import ForgotPassword from "./pages/student/auth/ForgotPassword";
import ResetPassword from "./pages/student/auth/ResetPassword";
import SecurityAppPage from "./pages/student/settings/SecurityAppPage";
import InstructionPage from "./pages/student/test/InstructionPage";
import ThankYouPage from "./pages/student/test/ThankYouPage";
import PrivacyPolicy from "./pages/student/auth/TermsPolicies";
import DesktopOnly from "./pages/common/DesktopOnly";

const RegisterStudent = lazy(() => import("./pages/student/auth/Register"));
const LoginStudent = lazy(() => import("./pages/student/auth/Login"));

export default function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);


  const { isLoggedIn, logoutError, USER_LOADING } = useSelector(
    (state) => state.studentAuth
  );

  useEffect(() => {
    if (
      window.location.pathname.match(/^\/student(\/.*)/) ||
      window.location.pathname.match(/^\/test\/start\/instruction\/[^/]+$/) ||
      window.location.pathname.match(/^\/test\/start\/end.*/)
    ) {
      dispatch(getStudent()).finally(() => {});
    }
  }, []);

  useEffect(() => {
    if (logoutError) {
      navigate("/");
    }
  }, [logoutError, navigate]);

  useEffect(() => {
    if (isLoggedIn && window.location.pathname === "/") {
      //console.log(window.location.pathname === "/");
      navigate("/student/dashboard");
    }
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (loader || USER_LOADING) {
    return <Loader />;
  }

  const isTestRoute =
    location?.pathname?.includes("test") &&
    location?.pathname?.includes("start");

  // //console.log("user", isLoggedIn);
 return isDesktop ? (
    <div className="app-content">
       <React.Fragment>
      {isLoggedIn ? (
        <>
          {!isTestRoute && (
            <StudentLayout>
              <Suspense fallback={<Loader />}>
                <Routes key={location.pathname}>
                  {StudentProfileRoutes()}
                  {StudentTestRoute()}
                  {StudentSettingsRoute()}
                  {StudentJobsRoute()}
                  {StudentDashRoute()}
                  {StudentInboxRoute()}
                  {StudentResultsRoute()}
                  {StudentCompaniesRoute()}
                  {/* <Route
                    path="/test/start/instruction/:id"
                    element={<InstructionPage />}
                  /> */}
                </Routes>
              </Suspense>
            </StudentLayout>
          )}
          <Routes key={location.pathname}>
            <Route path="/test/start/end/:id" element={<ThankYouPage />} />
            <Route path="/securityApp" element={<SecurityAppPage />} />
            <Route path="/student/test/:id/start" element={<TestHome />} />
          </Routes>

          {location.pathname.includes("/test/start/instruction/") && (
            <StudentLayout>
              <Routes>
                {" "}
                <Route
                  path="/test/start/instruction/:id"
                  element={<InstructionPage />}
                />
              </Routes>
            </StudentLayout>
          )}
        </>
      ) : (
        <Suspense fallback={<Loader />}>
          <Routes key={location.pathname}>
            <Route path="/" element={<LoginStudent />} />
            <Route path="/student" element={<RegisterStudent />} />
            <Route path="/securityApp" element={<SecurityAppPage />} />
            <Route path="/student/test/:id" element={<LoginStudent />} />
            <Route path="/login" element={<LoginStudent />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="/password/reset/:id" element={<ResetPassword />} />
            <Route path="student/me/failed" element={<NotAuth />} />
            <Route path="/terms&policies" element={<PrivacyPolicy />} />
          </Routes>
        </Suspense>
      )}
    </React.Fragment>
    </div>
  ) : (
    <DesktopOnly />
  );
   
}
