import Skeleton from "../../../loaders/Skeleton";
import React from "react";

const QuestionsNonAdaptive = ({
  question,
  answerIndex,
  setAnswerIndex,
  count,
  loading,
}) => {
  const handleChange = (e) => {
    setAnswerIndex(e.target.value);
  };
  // //console.log("non adaptive 2", answerIndex);

  // //console.log(count, "question");
  return (
    <>
      {loading ? (
        <Skeleton />
      ) : (
        question &&
        question.Options && (
          <div className="flex bg-[#F8F8F9] rounded-lg justify-between">
            <div className="w-11/12 flex flex-col gap-2 p-5">
              {/* {search.get(`${Number}`) !== "true" ? ( */}
              <div className="flex gap-2 font-semibold text-base w-full text-[#171717]">
                <h2>Q{count}.</h2>
                <h2 dangerouslySetInnerHTML={{ __html: question.Title }}></h2>?
                {/* <div className="self-end">{question.QuestionLevel}</div> */}
              </div>
              {question.image && (<img src={question.image} alt="question" className="w-full rounded-lg" />)}

              <div className="flex flex-col gap-3">
                <div className="flex gap-2">
                  <div className="flex w-5 justify-center">
                    <input
                      onChange={handleChange}
                      checked={parseInt(answerIndex) === 0}
                      name="option"
                      value={0}
                      type="radio"
                      id="answer-0"
                      className="w-3 h-3 p-[.4rem] checked:bg-none  checked:border checked:border-blue-700 border-secondary checked:p-0 border-2  ring-transparent ring-2 checked:ring-blue-700 ring-offset-2   self-center "
                    />{" "}
                  </div>
                  <label htmlFor="answer-0" className="self-center text-base">
                    {question.Options[0]}
                  </label>
                </div>
                <div className="flex gap-2">
                  <div className="flex w-5 justify-center">
                    <input
                      onChange={handleChange}
                      name="option"
                      type="radio"
                      value={1}
                      checked={parseInt(answerIndex) === 1}
                      id="answer-1"
                      className="w-3 h-3 p-[.4rem] checked:bg-none  checked:border checked:border-blue-700 border-secondary checked:p-0 border-2  ring-transparent ring-2 checked:ring-blue-700 ring-offset-2   self-center "
                    />{" "}
                  </div>
                  <label htmlFor="answer-1" className="self-center text-base">
                    {question.Options[1]}
                  </label>
                </div>
                <div className="flex gap-2">
                  <div className="flex w-5 justify-center">
                    <input
                      onChange={handleChange}
                      name="option"
                      checked={parseInt(answerIndex) === 2}
                      value={2}
                      type="radio"
                      id="answer-2"
                      className="w-3 h-3 p-[.4rem] checked:bg-none  checked:border checked:border-blue-700 border-secondary checked:p-0 border-2  ring-transparent ring-2 checked:ring-blue-700 ring-offset-2   self-center "
                    />{" "}
                  </div>
                  <label htmlFor="answer-2" className="self-center text-base">
                    {question.Options[2]}
                  </label>
                </div>
                <div className="flex gap-2">
                  <div className="flex w-5 justify-center">
                    <input
                      onChange={handleChange}
                      name="option"
                      value={3}
                      checked={parseInt(answerIndex) === 3}
                      type="radio"
                      id="answer-3"
                      className="w-3 h-3 p-[.4rem] checked:bg-none  checked:border checked:border-blue-700 border-secondary checked:p-0 border-2  ring-transparent ring-2 checked:ring-blue-700 ring-offset-2   self-center "
                    />{" "}
                  </div>
                  <label htmlFor="answer-3" className="self-center text-base">
                    {question.Options[3]}
                  </label>
                </div>
                {/* ))} */}
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default QuestionsNonAdaptive;
