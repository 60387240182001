import React, { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Loader from "../../../components/loaders/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  getAssessmentById,
  startTest,
} from "../../../redux/student/test/thunks/assessment";
import useTranslate from "../../../hooks/useTranslate";
import { FaAngleLeft } from "react-icons/fa";
import { toast } from "react-hot-toast";

const Instruction = () => {
  // useTranslate();
  const navigate = useNavigate();
  const { currentAssessment, START_TEST_LOADING } = useSelector(
    (state) => state.studentTest
  );
  const { id } = useParams();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const isAdaptive = searchParams.get("isAdaptive");

  const startAssess = async () => {
    const getCameraAccess = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        // Stop the video stream immediately after getting access
        stream.getTracks().forEach((track) => track.stop());

        // Camera access granted, start the test
        dispatch(
          startTest({
            testId: id,
            isAdaptive: searchParams.get("isAdaptive") === "true",
          })
        );

        toast.success("Camera access granted. Test starting...");
      } catch (error) {
        console.error("Camera access error:", error);
        toast.error("Camera access is required to start the test.");
        navigate(-1);
      }
    };

    try {
      if (!assessment?.config?.isCameraAccessRequired) {
        await dispatch(
          startTest({
            testId: id,
            isAdaptive: searchParams.get("isAdaptive") === "true",
          })
        );
        return;
      }
      await getCameraAccess();
    } catch (error) {
      console.error("Failed to start assessment:", error);
      toast.error(
        "An error occurred while starting the test. Please try again."
      );
      navigate(-1);
    }
  };

  useEffect(() => {
    dispatch(getAssessmentById(id));
  }, [dispatch, id]);

  const assessment = currentAssessment?.assessment;
  const questionTypes = {
    mcq: 0,
    compiler: 0,
    essay: 0,
    findAnswer: 0,
    video: 0,
  };

  let adaptiveNumber = 0;
  assessment?.topics?.forEach((topic) => {
    adaptiveNumber += topic?.totalL1Question || 0;
    switch (topic?.Type) {
      case "mcq":
        questionTypes.mcq +=
          topic.totalL1Question ||
          topic.totalL2Question ||
          topic.totalL3Question ||
          0;
        // questionTypes.mcq += topic.questions?.length || 0;
        break;
      case "compiler":
        questionTypes.compiler += topic.compiler?.length || 0;
        break;
      case "findAnswer":
        questionTypes.findAnswer += topic.findAnswers?.length || 0;
        break;
      case "essay":
        questionTypes.essay += topic.essay?.length || 0;
        break;
      case "video":
        questionTypes.video += topic.video?.length || 0;
        break;
      default:
        break;
    }
  });

  const numberCount =
    questionTypes.mcq +
    questionTypes.compiler +
    questionTypes.essay +
    questionTypes.findAnswer +
    questionTypes.video;

  console.log(assessment);

  return (
    <>
      <div className="font-dmSans">
        <div className="flex justify-between items-center mb-8 py-4">
          <div className="flex items-center gap-4">
            <button
              className="flex items-center justify-center h-10 w-10 sm:h-12 sm:w-12 rounded-lg bg-gray-200 shadow-sm hover:shadow-md transition-shadow duration-200 border border-gray-300"
              onClick={() => navigate(-1)}
            >
              <FaAngleLeft className="h-4 w-4 sm:h-6 sm:w-6" />
            </button>
            <h2 className="text-xl sm:text-2xl font-bold">
              Assessment Instructions
            </h2>
          </div>
        </div>

        <div className="bg-white rounded-md p-6 sm:p-8 shadow-lg border border-gray-200">
          <div className="flex flex-col lg:flex-row gap-8">
            {/* Left Section */}
            <div className="lg:w-1/2">
              <h2 className="text-xl font-semibold mb-4 pb-2 border-b">
                Important
              </h2>
              <ul className="space-y-4">
                <li className="rounded-lg bg-gray-50 p-4 shadow-sm border border-gray-100">
                  <h3 className="font-semibold mb-2">Proctoring Settings</h3>
                  <p className="text-gray-600">
                    - Full-screen mode is mandatory, and exiting it will log you
                    out.
                    <br />- All popups, tab switches, or window changes will
                    terminate your session.
                  </p>
                </li>
                <li className="rounded-lg bg-gray-50 p-4 shadow-sm border border-gray-100">
                  <h3 className="font-semibold mb-2">Plagiarism Policy</h3>
                  <p className="text-gray-600">
                    - Copy-paste is disabled in the problem statement and code
                    editor.
                    <br />- Sharing answers or using online tools is strictly
                    prohibited.
                    <br />- Violations can lead to permanent account suspension
                    or blacklisting.
                  </p>
                </li>
                <li className="rounded-lg bg-gray-50 p-4 shadow-sm border border-gray-100">
                  <h3 className="font-semibold mb-2">Test Environment</h3>
                  <p className="text-gray-600">
                    - Use a stable internet connection. Avoid mobile tethering.
                    <br />- Attempt the test in a private browsing window to
                    prevent interference from browser extensions.
                    <br />- Close all programs and notifications before
                    starting.
                  </p>
                </li>
              </ul>
            </div>

            {/* Right Section */}
            <div className="lg:w-1/2 space-y-6">
              <div className="rounded-lg bg-gray-50 p-4 shadow-sm border border-gray-100">
                <p className="mb-2">
                  <strong>Number of Questions:</strong>{" "}
                  <span className="text-gray-600">
                    {isAdaptive === "false" ? numberCount : adaptiveNumber}
                  </span>
                </p>
                <p>
                  <strong>Types of Questions:</strong>{" "}
                  <span className="text-gray-600">
                    {isAdaptive === "false"
                      ? Object.keys(questionTypes)
                          .filter((key) => questionTypes[key])
                          .map((key, index) => (
                            <span key={key}>
                              {index > 0 && ", "}
                              {key.charAt(0).toUpperCase() + key.slice(1)}
                            </span>
                          ))
                      : "Mcq"}
                  </span>
                </p>
              </div>

              {isAdaptive === "true" && (
                <div className="rounded-lg bg-gray-50 p-4 shadow-sm border border-gray-100">
                  <ul className="space-y-3">
                    <li className="flex items-start gap-2">
                      <span className="h-1.5 w-1.5 rounded-full bg-gray-400 mt-2"></span>
                      <span>
                        There are three levels of questions: L1, L2, and L3.
                      </span>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="h-1.5 w-1.5 rounded-full bg-gray-400 mt-2"></span>
                      <span>
                        To advance to the next level, you must correctly answer
                        at least one-third of the questions.
                      </span>
                    </li>
                    <li className="mt-4 bg-gray-100 rounded-lg p-4">
                      <span className="font-semibold block mb-2">Example:</span>
                      <p className="text-gray-600 text-sm leading-relaxed">
                        If there are a total of 5 questions, you need to answer
                        2 questions correctly at L1 to advance to L2. Similarly,
                        you must answer 2 questions correctly at L2 to reach L3.
                        If you fail to answer 2 questions correctly at L1, you
                        will remain at L1 for all 5 questions.
                      </p>
                    </li>
                  </ul>
                </div>
              )}

              {isAdaptive === "false" && (
                <div className="rounded-lg bg-gray-50 p-4 shadow-sm border border-gray-100">
                  <p>
                    <strong>Time:</strong>{" "}
                    <span className="text-gray-600">
                      {assessment?.totalTime} mins
                    </span>
                  </p>
                </div>
              )}

              {/* <div className="rounded-lg bg-gray-50 p-4 shadow-sm border border-gray-100">
                <p>
                  <strong>Total Questions:</strong>{" "}
                  <span className="text-gray-600">7</span>
                </p>
                <p>
                  <strong>Score:</strong>{" "}
                  <span className="text-gray-600">265</span>
                </p>
              </div> */}

              <div className="pt-4">
                <button
                  onClick={startAssess}
                  disabled={START_TEST_LOADING}
                  className="bg-accent text-white uppercase px-8 py-3 rounded-lg hover:shadow-lg transition-shadow duration-200 inline-flex items-center justify-center gap-2 disabled:opacity-70"
                >
                  {START_TEST_LOADING ? "Test Starting" : "Start"}
                  {START_TEST_LOADING && <Loader />}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Instruction;
