import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LuEye } from "react-icons/lu";
import toast from "react-hot-toast";
import { resetPassword } from "../../../redux/student/auth/studentAuthSlice";

const ResetPassword = () => {
  const [error, setError] = useState(false);
  const { id } = useParams();
  const [type, setType] = useState("password");
  const [typeConfirm, setTypeConfirm] = useState("password");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [Credentials, setCredentials] = useState({
    Password: "",
    confirmPassword: "",
  });

  const [isUppercase, setIsUppercase] = useState(false);
  const [isLowercase, setIsLowercase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [isMinLength, setIsMinLength] = useState(false);

  const changeHandler = (e) => {
    let cred = e.target.name;
    let val = e.target.value;
    setCredentials((prev) => {
      return { ...prev, [cred]: val };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { Password, confirmPassword } = Credentials;

    if (Password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    const data = {
      token: id,
      password: Password,
      confirmPassword,
    };

    try {
      const response = await dispatch(resetPassword(data));
      if (response.meta.requestStatus === "fulfilled") {
        toast.success("Password changed successfully!");
        setCredentials({ Password: "", confirmPassword: "" });
        navigate("/");
      }
    } catch (error) {
      toast.error("Failed to reset password");
    }
  };

  const isConfirmDisabled =
    !Credentials.confirmPassword ||
    !isUppercase ||
    !isLowercase ||
    !hasNumber ||
    !isMinLength;

  useEffect(() => {
    const password = Credentials.Password;
    setIsUppercase(/[A-Z]/.test(password));
    setIsLowercase(/[a-z]/.test(password));
    setHasNumber(/[0-9]/.test(password));
    setIsMinLength(password.length >= 8);
  }, [Credentials.Password]);

  return (
    <form className="font-dmSans">
      <div className="bg-base-100 shadow-xl h-full min-h-[100vh] font-dmSans grid grid-cols-5 items-center">
        <figure className="w-full h-full bg-login bg-no-repeat bg-cover bg-center hidden lg:block col-span-2"></figure>

        <div className="card-body col-span-3">
          <img src="/quali.png" alt="logo" className="w-60 mx-auto mb-8" />

          <h2 className="font-bold text-2xl text-center mt-6">
            Reset Password
          </h2>

          {/* Password Input */}
          <div className="w-full max-w-xs mx-auto flex mt-6 rounded-md bg-snow relative">
            <input
              name="Password"
              onChange={changeHandler}
              value={Credentials.Password}
              type={type}
              placeholder="Password"
              className="input border-none focus:outline-none input-md w-full max-w-xs bg-snow mx-auto"
            />
            <button
              className="absolute inset-y-0 right-0 flex items-center pr-3 focus:outline-none"
              onClick={(e) => {
                e.preventDefault();
                setType(type === "text" ? "password" : "text");
              }}
            >
              <LuEye className="text-gray-400 text-2xl" />
            </button>
          </div>

          {/* Confirm Password Input */}
          <div className="w-full max-w-xs mx-auto flex mt-6 rounded-md bg-snow relative">
            <input
              name="confirmPassword"
              onChange={changeHandler}
              value={Credentials.confirmPassword}
              type={typeConfirm}
              placeholder="Confirm Password"
              className="input border-none focus:outline-none input-md w-full max-w-xs bg-snow mx-auto"
            />
            <button
              className="absolute inset-y-0 right-0 flex items-center pr-3 focus:outline-none"
              onClick={(e) => {
                e.preventDefault();
                setTypeConfirm(typeConfirm === "text" ? "password" : "text");
              }}
            >
              <LuEye className="text-gray-400 text-2xl" />
            </button>
          </div>

          {/* Validation Checks */}
          <div className="mt-4 max-w-xs mx-auto">
            <p
              className={`text-sm ${
                isUppercase ? "text-green-500" : "text-gray-400"
              }`}
            >
              {isUppercase ? "✓" : "✗"} At least one uppercase letter
            </p>
            <p
              className={`text-sm ${
                isLowercase ? "text-green-500" : "text-gray-400"
              }`}
            >
              {isLowercase ? "✓" : "✗"} At least one lowercase letter
            </p>
            <p
              className={`text-sm ${
                hasNumber ? "text-green-500" : "text-gray-400"
              }`}
            >
              {hasNumber ? "✓" : "✗"} At least one number
            </p>
            <p
              className={`text-sm ${
                isMinLength ? "text-green-500" : "text-gray-400"
              }`}
            >
              {isMinLength ? "✓" : "✗"} Minimum 8 characters
            </p>
          </div>

          {/* Submit Button */}
          <button
            className={`btn hover:bg-accent bg-accent rounded-md border-none mt-6 focus:outline-none w-full max-w-xs mx-auto text-white ${
              isConfirmDisabled ? "bg-gray-400 cursor-not-allowed" : ""
            }`}
            onClick={handleSubmit}
            disabled={isConfirmDisabled}
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

export default ResetPassword;
