import React, { useEffect } from "react";
import Header from "../../../components/student/test/overview/Header";
import About from "../../../components/student/test/overview/About";
import Info from "../../../components/student/test/overview/Info";
import Description from "../../../components/student/test/overview/Description";
import { useDispatch, useSelector } from "react-redux";
import {
  useBeforeUnload,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  getAssessmentById,
  startTest,
} from "../../../redux/student/test/thunks/assessment";
import LoadTest from "../../../Loader";
import useTranslate from "../../../hooks/useTranslate";

const TestsOverview = () => {
  // useTranslate();
  const navigate = useNavigate();

  // const { user } = useSelector((state) => state.studentAuth);
  const { currentAssessment, START_TEST_LOADING, GET_TEST_LOADING } =
    useSelector((state) => state.studentTest);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const isAdaptive = searchParams.get("isAdaptive");
  const startAssess = () => {
    //console.log("startAssess", searchParams.get("isAdaptive"));
    // handleFullscreen();
    dispatch(
      startTest({ testId: id, isAdaptive: searchParams.get("isAdaptive") })
    )
      .then(() => {
        //console.log("timer set");
        // dispatch(setTimerTest())
        // const intervalId = setInterval(() => {
        //   dispatch(updateTimer());
        // }, 1000);
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  // const assessment = useSelector((state) => state.test.test);
  // // const {studentResponses} = useSelector((state) => state.test);
  // const searchParams = new URLSearchParams(window.location.search);
  // const assessmentId = searchParams.get("assessment");

  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getAllTests());
  // }, []);

  // useEffect(() => {
  //   dispatch(getTest(assessmentId));
  //   dispatch(getTestResultPage(assessmentId));

  // }, [dispatch, assessmentId]);

  // useEffect(() => {
  //   dispatch(getStudentResponse(assessmentId));
  // }
  // , [assessmentId]);
  // const responses = studentResponses.map((responseId) => {
  //   dispatch(getStudentResponse(responseId))
  //   return responseId;
  // }
  // );

  useEffect(() => {
    //console.log(id);
    dispatch(getAssessmentById(id));
  }, []);

  // save it off before users navigate away
  // useEffect(() => {
  //   const onBeforeUnload = (ev) => {
  //     //#############
  //     //console.log("SOME CODE HERE");
  //     //#############

  //     ev.returnValue = "Anything you wanna put here!";
  //     return "Anything here as well, doesn't matter!";
  //   };

  //   window.addEventListener("beforeunload", onBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", onBeforeUnload);
  //   };
  // }, []);
  const assessment = currentAssessment?.assessment;
  const handleFullscreen = () => {
    const element = document.documentElement; // or any other element you want to make fullscreen
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      // Firefox
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      // Chrome, Safari and Opera
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      // IE/Edge
      element.msRequestFullscreen();
    }
  };
  return (
    <>
      {!GET_TEST_LOADING ? (
        <>
          <Header />
          <Info user={currentAssessment.sender} assessment={assessment} />

          <About Description={assessment?.description} />

          <div className="bg-[#8F92A1] font-dmSans  bg-opacity-5 p-5 mb-1 rounded-b-xl">
            <div className="flex justify-between gap-2 mb-5 items-center">
              <h2 className="font-bold text-base">Test Description</h2>
              <button
                className="bg-accent text-white rounded-md h-10 font-dmSans px-10  flex items-center"
                onClick={() => {
                  navigate(
                    `/test/start/instruction/${id}?isAdaptive=${isAdaptive}`
                  );
                }}
              >
                {/* {START_TEST_LOADING
                  ? "Test starting"
                  : " Start the Assessment "}
                {START_TEST_LOADING && <Loader />} */}{" "}
                Start the Assessment
              </button>
            </div>
            <Description topics={assessment?.topics} />
          </div>
        </>
      ) : (
        <LoadTest />
      )}

      {/* <Toggle assessment={assessment}  /> */}
    </>
  );
};

export default TestsOverview;
