import React from "react";
import { CgStopwatch } from "react-icons/cg";

const Description = ({ topics }) => {
  const handleCalculateTime = (topic) => {
    let totalMcq = 0,
      totalEssay = 0,
      totalVideo = 0,
      totalCompiler = 0,
      totalFindAnswer = 0;

    if (topic.Type === "essay") {
      totalEssay += topic.essay?.reduce((acc, curr) => {
        //console.log(parseInt(curr.Duration));
        return acc + parseInt(curr.Duration);
      }, 0);
    }
    if (topic.Type === "video") {
      totalVideo += topic.video?.reduce((acc, curr) => {
        return acc + parseInt(curr.Duration);
      }, 0);
    }
    if (topic.Type === "compiler") {
      totalCompiler += topic.compiler?.reduce((acc, curr) => {
        return acc + parseInt(curr.Duration);
      }, 0);
    }
    if (topic.Type === "findAnswer") {
      totalFindAnswer += topic.findAnswers?.reduce((acc, curr) => {
        return acc + parseInt(curr.Duration);
      }, 0);
    }

    if (topic.Type === "mcq") {
      totalMcq += topic.questions?.reduce((acc, curr) => {
        return acc + parseInt(curr.Duration);
      }, 0);
    }

    const total =
      totalMcq + totalEssay + totalVideo + totalCompiler + totalFindAnswer;

    // //console.log(
    //   total,
    //   "total",
    //   totalMcq,
    //   totalEssay,
    //   totalVideo,
    //   totalCompiler,
    //   totalFindAnswer
    // );
    return total;
  };
  //console.log(topics);
  return (
    <div className="font-dmSans bg-opacity-5">
      {/* card */}
      <div className="flex flex-row justify-start flex-wrap gap-8">
        {topics?.map((topic) => (
          <div className="flex  flex-wrap gap-8">
            <div className="w-[17.5rem] h-40 bg-white rounded-md p-4 relative">
              <h2 className="font-bold mb-3 first-letter:uppercase text-base">
                {topic.Heading}
              </h2>
              <p className="text-xs text-gray-400 line-clamp-4 mb-4 first-letter:uppercase">
                {topic?.Description?.slice(0, 100)}...
              </p>
              <span className="flex justify-between absolute bottom-5">
                <div className="text-gray-400 flex gap-1">
                  {/* <CgStopwatch className="text-xl" />{" "} */}
                  <p className="text-xs self-center first-letter:uppercase">
                    {" "}
                    {/* {handleCalculateTime(topic)} */}{" "}
                    {topic.Type === "mcq"
                      ? "Multiple Choice Questions"
                      : topic.Type}
                  </p>
                </div>

                <p className="text-xs self-center text-blue-500 tracking-[-0.4px]">
                  {/* type */}
                </p>
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Description;
